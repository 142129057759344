// NOTE: below are fallback phrases for consent manager config
const fallbackDictionary = {
  "data_collection_warning": "We use cookies (and other similar technologies) to collect data to improve your shopping experience. ",
  "agree_on_data_collection":"By using our website, you're agreeing to the collection of data as described in our ",
  "privacy_policy": "Privacy Policy",
  "change_preferences": "You can change your preferences at any time",
  "data_collection_preferences": "Website Data Collection Preferences",
  "manage_data_collection_preferences": "Manage Website Data Collection Preferences",
  "use_data_by_cookies": "uses data collected by cookies and JavaScript libraries to improve your shopping experience.",
  "by_using": "By using our website, you're agreeing to our",
  "data_categories_table": "The table below outlines how we use this data by category. To opt out of a category of data collection, select 'No' and save your preferences.",
  "unsaved_changes": "You have unsaved changes",
  "close_without_changes": "You have unsaved changes to your data collection preferences. Are you sure you want to close without saving?",
};
const createDictionary = (obj) => {
  const localizedDictionary = obj.translations;
  const translationKeys = Object.keys(localizedDictionary);
  const isEmpty = !translationKeys.length;

  if (isEmpty) return localizedDictionary;

  return translationKeys.reduce((dictionary, key) => {
      dictionary[key.split('.').pop()] = localizedDictionary[key];
      return dictionary;
  }, {});
};
const composeTranslations = (stringJson) => {
   if (!stringJson) return fallbackDictionary;

    const localDictionary = createDictionary(JSON.parse(stringJson))
    return {...fallbackDictionary, ...localDictionary};
};
const translations = composeTranslations(window.consentManagerTranslations);

const TRACKING_COOKIE = "bc_consent";

// Create hidden div for consent manager modal binding
function injectConsentBanner() {
  var elem = document.createElement("div");
  elem.id = "consent-manager";
  elem.style.cssText =
    "position: fixed;left: 0;right: 0;bottom: 0;z-index: 1000; background-color:rgba(255,255,255,0.0)";
  document.body.appendChild(elem);
}

// Create div for consent manager update button modal binding
function injectConsentBannerUpdate() {
  // Don't create the consent update banner if it already exists
  if (document.getElementById("consent-manager-update-banner")) {
    return;
  }

  const height = "48px";
  const backgroundColor = "#454545";
  const textColor = "#ffffff";

  const FONT =
    "font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-smoothing: antialiased;font-size: 14px;font-weight: 400;letter-spacing: -0.05px;line-height: " +
    height +
    ";";

  var banner = document.createElement("div");
  banner.id = "consent-manager-update-banner";
  banner.style.cssText =
    "height: " +
    height +
    " ; background-color: " +
    backgroundColor +
    ";" +
    FONT;

  banner.innerHTML =
    '<a tabindex="0" style="color:' +
    textColor +
    ';margin-left:16px;" onclick="event.stopPropagation(); window.consentManager.openConsentManager();">' +
    translations.manage_data_collection_preferences +
    '</a>';

  document.body.appendChild(banner);
}

var havePreferences = document.cookie.indexOf(TRACKING_COOKIE) > 0;

injectConsentBanner();

if (havePreferences) {
  injectConsentBannerUpdate();
}

function checkAndLoadScript(script, allowList = []) {
  const bcCookieConsent = script.dataset.bcCookieConsent;
  if (typeof bcCookieConsent === "undefined") {
    return false;
  }
  const consentCategory = Number(bcCookieConsent);
  if (isNaN(consentCategory)) {
    return false;
  }

  const isAllowed = allowList.includes(consentCategory);

  if (isAllowed && script.type === "text/plain") {
    const cloneScript = script.cloneNode(true);
    cloneScript.type = 'text/javascript';
    const parentElement = script.parentElement;
    script.remove();

    const scriptLoadedPromise = cloneScript.src && new Promise((resolve) => {
      cloneScript.onload = () => {
        resolve();
      };
    });
    parentElement.appendChild(cloneScript);

    return scriptLoadedPromise;
  }
}

/**
 * Loads scripts for which cookie consent is given.
 * Fires consentScriptsLoaded event when all remote scripts are loaded
 * @param {Object} preferenceRequest
 *
 * @return {void}
*/
function loadAllowedScripts(preferenceRequest) {
  const consentScripts = document.querySelectorAll('[data-bc-cookie-consent]');
  const loadedScripts = Array.from(consentScripts).map(script => checkAndLoadScript(script, preferenceRequest.allow)).filter(promise => promise);

  Promise.all(loadedScripts).then(() => {
    window.dispatchEvent(new Event('consentScriptsLoaded'));
  });
}

// Setup consent manager config
window.consentManagerConfig = function(exports) {
  var ga4ConsentModeEnabled = window.consentManagerStoreConfig().GA4ConsentModeEnabled;
  var alwaysIncludeScriptsWithConsentTag = window.consentManagerStoreConfig().AlwaysIncludeScriptsWithConsentTag;

  // Segment preference keys to BC enum
  const preferenceEnumMap = {
    functional: 2,
    marketingAndAnalytics: 3,
    advertising: 4
  };

  function transformPreferences(prefs) {
      const transformed = {
          allow: [],
          deny: [],
      }
      const preferences = prefs.customPreferences || {};

      Object.keys(preferenceEnumMap).forEach(function(prefKey) {
          const cat = preferenceEnumMap[prefKey];

          if (preferences[prefKey]) {
              transformed.allow.push(cat);
          } else {
              transformed.deny.push(cat);
          }
      });

      return transformed;
  }

  if (ga4ConsentModeEnabled && !window.consentManagerStoreConfig().BODLConsentLoadedEventInStorefront) {
      window.addEventListener('load', function() {
          if (typeof triggerConsentLoadedEvent === 'function') {
              const preferences = transformPreferences(exports.preferences.loadPreferences());
              triggerConsentLoadedEvent(preferences.allow);
          }
      });
  }

  if (alwaysIncludeScriptsWithConsentTag) {
      const preferences = transformPreferences(exports.preferences.loadPreferences());
      loadAllowedScripts(preferences);
  }

  // Bind to preferences saved event
  exports.preferences.onPreferencesSaved(function(prefs) {
    var improvedConsentManagerEnabled = window.consentManagerStoreConfig().improvedConsentManagerEnabled;
    let preferenceRequest = transformPreferences(prefs);

    fetch("/api/storefront/consent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(preferenceRequest)
    })
      .then(function() {
        if (ga4ConsentModeEnabled && typeof triggerConsentUpdatedEvent === 'function') {
            triggerConsentUpdatedEvent(preferenceRequest.allow)
        }

        if (improvedConsentManagerEnabled) {
          loadAllowedScripts(preferenceRequest);
          var checkPreferences = document.cookie.indexOf(TRACKING_COOKIE) > 0;
          if (checkPreferences) {
            injectConsentBannerUpdate();
          }
        } else {
          document.location.reload(true);
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  });

  var React = exports.React;

  var hasPrivacyPolicy =
    window.consentManagerStoreConfig().privacyPolicyUrl !== undefined &&
    window.consentManagerStoreConfig().privacyPolicyUrl !== null &&
    window.consentManagerStoreConfig().privacyPolicyUrl !== "";

  var bannerContent = React.createElement(
    "span",
    null,
    translations.data_collection_warning,
    hasPrivacyPolicy
      ? translations.agree_on_data_collection
      : "",
    hasPrivacyPolicy
      ? React.createElement(
          "a",
          {
            href: window.consentManagerStoreConfig().privacyPolicyUrl,
            target: "_blank"
          },
          translations.privacy_policy
        )
      : "",
    hasPrivacyPolicy ? "." : ""
  );

  var bannerSubContent = translations.change_preferences;
  var preferencesDialogTitle = translations.data_collection_preferences;

  var preferencesDialogContent = React.createElement(
    "span",
    null,
    window.consentManagerStoreConfig().storeName +
      ` ${translations.use_data_by_cookies} `,
    hasPrivacyPolicy ? `${translations.by_using} ` : "",
    hasPrivacyPolicy
      ? React.createElement(
          "a",
          {
            href: window.consentManagerStoreConfig().privacyPolicyUrl,
            target: "_blank"
          },
          translations.privacy_policy
        )
      : "",
    hasPrivacyPolicy ? ". " : "",
    translations.data_categories_table
  );

  var cancelDialogTitle = translations.unsaved_changes;
  var cancelDialogContent = translations.close_without_changes;

  var writeKey =
    window.consentManagerStoreConfig === undefined
      ? ""
      : window.consentManagerStoreConfig.writeKey;

  return {
    container: "#consent-manager",
    writeKey: writeKey,
    shouldRequireConsent: function() {
      return !havePreferences;
    },
    bannerContent: bannerContent,
    bannerSubContent: bannerSubContent,
    bannerBackgroundColor: "#454545",
    preferencesDialogTitle: preferencesDialogTitle,
    preferencesDialogContent: preferencesDialogContent,
    cancelDialogTitle: cancelDialogTitle,
    cancelDialogContent: cancelDialogContent,
    closeBehavior: "dismiss",
    shouldReload: false
  };
};
